import { SummaryCardsContainer, SummaryCardWithIcon, MerchantPotIcon, PersonPlusIcon, DeliveryTruckIcon, CheckListIcon } from "suregifts-component-lib";
import { SummaryData } from "../../services/general";

type SummaryCardsSectionProps = {
  data?: SummaryData;
};
export const SummaryCardsSection = ({ data }: SummaryCardsSectionProps) => {
  return (
    <div style={{ marginTop: "40px" }}>
      <SummaryCardsContainer>
        <SummaryCardWithIcon title={data?.totalCompanies} info="total compaines" icon={MerchantPotIcon} />
        <SummaryCardWithIcon title={data?.totalBusiness} info="Total business unit" icon={PersonPlusIcon} />
        <SummaryCardWithIcon title={data?.totalOrders} info="total ordees" icon={DeliveryTruckIcon} />
        <SummaryCardWithIcon title={data?.totalBalance} info="total wallet balance" icon={CheckListIcon} />
      </SummaryCardsContainer>
    </div>
  );
};
