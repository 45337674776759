import { useMutation, useQuery } from "react-query";
import { useAxios, Count, ServerError, ApprovalStatusType, PageFilter, TransactionStatusType } from "suregifts-component-lib";
export const useGetRecentPayments = () => {
  const axios = useAxios();

  const path = "/transactions/api/payments";
  return useQuery<Count<PaymentData>, ServerError, PaymentData[]>({
    queryKey: ["recent-payments"],
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: { page: 1, size: 8 },
      }),
    select: (data) => data.items,
  });
};
export const useGetPayments = (filter: PaymentFilter) => {
  const axios = useAxios();

  const path = "/transactions/api/payments";
  return useQuery<Count<PaymentData>, ServerError>({
    queryKey: ["payments", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};
export const useExportPayments = (filter: PaymentFilter) => {
  var axios = useAxios();

  const path = `/transactions/api/payments/export`;
  return useMutation(() => {
    return axios.get(path, {
      params: filter,
    });
  });
};

export const useRejectPayment = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, RejectPaymentModel>((data) => {
    const path = `/transactions/api/payments/${data.paymentId}/reject`;
    return axios.post(path, data);
  });
};

export const useApprovePayment = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, string>((paymentId) => {
    const path = `/transactions/api/payments/${paymentId}/approve`;
    return axios.post(path);
  });
};

export interface RejectPaymentModel {
  rejectionReason: string;
  paymentId: any;
}
interface PaymentData {
  paymentNumber: string;
  amount: string;
  paymetMethodId: number;
  paymentMethod: string;
  paymentTypeId: number;
  paymentType: string;
  approvalStatusId: number;
  approvalStatus: string;
  paymentStatusId: number;
  paymentStatus: string;
  unit: string;
  name: string;
  email: string;
  narration: string;
  dateCreated: string;
  reviewedTime: string;
  reviewedBy: string;
  rejectionReason: string;
  extraInfo: any;
}
interface PaymentFilter extends PageFilter {
  fromDate?: Date;
  toDate?: Date;
  paymentType: any;
}
