import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { DialogTitle, FormButton, FormInput, TitleAndDetailsView, YupShape, formatMoney } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import * as yup from "yup";
import { ChangeOwnerModel, useChangeOwner } from "../../../services/business";
import { useBusinessModalState } from "./useBusinessModalState";

export const schema = yup
  .object<YupShape<ChangeOwnerModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required().email(),
  })
  .required();
type ChangeOwnerModalProps = {
  handleClose: () => void;
};
function ChangeOwnerModal({ handleClose = () => {} }: ChangeOwnerModalProps) {
  const { info, reload } = useBusinessModalState();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<ChangeOwnerModel>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const { mutate: changeOwner, isLoading } = useChangeOwner(info?.businessId);
  const submitForm = (data) => {
    changeOwner(data, {
      onSuccess: () => {
        toast.success("Business owner changed successfully.");
        reload();
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth={"xs"}>
      <DialogTitle title="Change Owner" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <FormInput label="First Name" helperText={errors.firstName?.message} {...register("firstName")} />
          <FormInput label="Last Name" helperText={errors.firstName?.message} {...register("lastName")} />
          <FormInput label="Email" helperText={errors.firstName?.message} {...register("email")} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Send" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default ChangeOwnerModal;
