import { Box, Divider, Stack, Typography, Tab, Tabs, IconButton } from "@mui/material";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { EditPencilIcon, FormButton, useDocumentTitle } from "suregifts-component-lib";
import { useToggleBusinessStatus as useToggleBusinessStatus } from "../../services/business";
import { useBusinessInfoScreenContext } from "./BusinessInfoScreenContext";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { useState } from "react";
import ChangeOwnerModal from "./modals/ChangeOwnerModal";

export function BusinessInfoView() {
  const { business: info } = useBusinessInfoScreenContext();

  return (
    <>
      <Box
        sx={{
          borderRadius: "1.875rem",
          bgcolor: "#17171c",
          boxShadow: 3,
          paddingInline: "1.875rem",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            paddingY: "1.875rem",
            flexWrap: "wrap",
            gap: "3.125rem",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", gap: "1.885rem" }}>
            <Box
              component={"img"}
              sx={{ width: "6.25rem", height: "6.25rem", borderRadius: "3.125rem", backgroundColor: "secondary.main", objectFit: "contain" }}
              src={info?.logo}
            ></Box>
            <UnitInfoView />
          </Box>
          <ManagerInfoView />
          <Divider flexItem orientation="vertical" />
          <WalletBalanceView />
        </Box>
        <TabControl />
      </Box>
    </>
  );
}
const TabControl = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Tabs
      sx={{
        "& .MuiTabs-indicator": {
          bgcolor: "white",
        },
      }}
      onChange={(e, value) => {
        setSearchParams({ tab: value });
      }}
      value={searchParams.get("tab") || "transactions"}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
    >
      <Tab value="orders" label="Orders" />
      <Tab value={"transactions"} label="Transactions" />
    </Tabs>
  );
};
function UnitInfoView({}) {
  const { business: info, reload } = useBusinessInfoScreenContext();
  const { mutate: toggleStatus, isLoading } = useToggleBusinessStatus(info?.businessId);
  return (
    <Box>
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 700, lineHeight: "1.8125rem" }}>{info?.name}</Typography>
      <FormButton
        sx={{
          height: "2.5rem",
          width: "7.5rem",
          borderRadius: "0.875rem",
          mt: "1rem",
        }}
        onClick={() =>
          toggleStatus(undefined, {
            onSuccess: () => {
              toast.success("Business status changed successfully.");
              reload();
            },
          })
        }
        loading={isLoading}
        label={info?.activated ? "Deactivate" : "Activate"}
        color={info?.activated ? "primary" : "success"}
      />
    </Box>
  );
}
function ManagerInfoView() {
  const { business: info } = useBusinessInfoScreenContext();
  const [changeOwner, setChangeOwner] = useState(false);
  return (
    <Box>
      <Typography sx={{ textTransform: "uppercase", color: "text.secondary", fontSize: "0.625rem" }}>Account Manager</Typography>
      <Typography
        sx={{
          fontSize: "1.25rem",
          color: "text.primary",
          fontWeight: 700,
          lineHeight: "1.5rem",
        }}
      >
        {info?.ownerName}
      </Typography>
      <Typography sx={{ color: "success.main" }}>
        {info?.ownerEmail}
        <IconButton onClick={() => setChangeOwner(true)} title="Change Owner">
          <EditPencilIcon />
        </IconButton>
      </Typography>
      {changeOwner && <ChangeOwnerModal handleClose={() => setChangeOwner(false)} />}
    </Box>
  );
}

const WalletBalanceView = () => {
  const { business: info } = useBusinessInfoScreenContext();
  const navigate = useNavigate();
  return (
    <Box display="flex" alignItems={"flex-start"}>
      <Box sx={{ width: "2.3125rem", height: "2.3125rem" }} component={"img"} src="/assets/images/wallet.png"></Box>
      <Box sx={{ ml: "1.125rem" }}>
        <Box sx={{ fontSize: "0.625rem", lineHeight: "0.75rem", color: "text.secondary", textDecoration: "uppercase" }}>WALLET BALANCE</Box>
        <Box sx={{ lineHeight: "3rem", fontSize: "2.4375rem", fontWeight: 700 }}>{info?.walletBalance}</Box>
        <Stack spacing={2} direction="row" sx={{ mt: "0.5625rem" }}>
          <FundButton label="Top-up" onClick={() => navigate("credit")} />
          <FundButton label="Debit" onClick={() => navigate("debit")} />
        </Stack>
      </Box>
    </Box>
  );
};
const FundButton = styled(FormButton)({
  height: "1.5625rem",
  width: "7.5rem",
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "0.25rem",
  boxSizing: "border-box",
});
