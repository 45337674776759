import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { OrderInfoDto } from "../../services/order";
import { OrderStatusLabel } from "suregifts-component-lib";

interface TitleInfoViewProps {
  title: string;
  info: string | React.ReactNode;
}

export function TitleInfoView({ title, info }: TitleInfoViewProps) {
  return (
    <Box sx={{}}>
      <Typography sx={{ textTransform: "uppercase", color: "text.secondary", fontSize: "0.625rem" }}>{title}</Typography>
      {typeof info === "string" ? (
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: 700,
          }}
        >
          {info}
        </Typography>
      ) : (
        <>{info}</>
      )}
    </Box>
  );
}
