import { useMutation, useQuery } from "react-query";
import { Count, DeliveryStatusType, OrderStatusType, PageFilter, ServerError, useAxios } from "suregifts-component-lib";

export const useGetRecentOrders = () => {
  const path = "/orders/api";
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError, OrderData[]>({
    queryKey: ["recent-orders"],
    keepPreviousData: true,
    queryFn: () =>
      axios.get(path, {
        params: {
          page: 1,
          size: 8,
        },
      }),
    select: (data) => data.items,
  });
};
export const useGetOrders = (filter: OrderFilter) => {
  const path = "/orders/api";
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError>({
    queryKey: ["orders", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};
export const useGetOrderById = (orderId?: string) => {
  const axios = useAxios();
  return useQuery<OrderInfoDto, ServerError>({
    queryKey: ["orders-byId", { orderId }],
    keepPreviousData: true,
    queryFn: () => axios.get(`orders/api/${orderId}`, {}),
  });
};
export const useResendVoucher = (orderId?: any) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, number>((recipientId: number) => {
    return axios.post("/orders/api/resend-voucher", {
      orderId,
      orderItemId: recipientId,
    });
  });
};
export interface ResendVoucherModel {
  orderItemId: number;
  orderId: number;
}
export enum BusinessOrderType {
  Recipients = 1,
  Download = 2,
}

export interface OrderFilter extends PageFilter {
  businessId?: any;
}
export interface OrderData {
  subTotal: string;
  statusId: number;
  status: string;
  quantity: number;
  id: number;
  orderNumber: string;
  dateCreated: string;
  invoiceNumber: string;
  orderTypeId: number;
  orderType: string;
  narration: string;
  merchantName: string;
  scheduledTime: string;
  unit: string;
  name: string;
  email: string;
}

export interface OrderInfoDto extends OrderData {
  rcNumber: string;
  businessLogo: string;
  recipients: RecipientData[];
}

export interface RecipientData {
  id: number;
  amount: string;
  statusId: number;
  status: string;
  email: string;
  firstName: string;
  lastName: string;
}
