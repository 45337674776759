import { Dialog, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { DialogTitle, TitleAndDetailsView, dateOnly } from "suregifts-component-lib";
import moment from "moment";
import { WalletTransactionData } from "../../../services/transaction";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function TransactionInfoModal() {
  const [data] = useState(() => {
    const data = sessionStorage.getItem("TRANSACTION_DATA");
    if (!data) return {} as WalletTransactionData;
    return JSON.parse(data) as WalletTransactionData;
  });

  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };

  return (
    <Dialog open maxWidth={"sm"}>
      <DialogTitle title="Transaction Details" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <TitleAndDetailsView title="Company Name" details={data?.name} />
          <TitleAndDetailsView title="Business Unit" details={data?.unit} />
          <TitleAndDetailsView title="Email" details={data?.email} />
          <TitleAndDetailsView title="Transaction ID" details={data?.transactionId} />
          <TitleAndDetailsView title="Date" details={dateOnly(data?.dateCreated)} />
          <TitleAndDetailsView title="Narration" details={data?.narration} />
          <TitleAndDetailsView title="Transaction Type" details={data?.transactionType} />
          <TitleAndDetailsView title="Amount" details={data?.amount} color={data.isCredit ? "success.main" : "error.main"} />
          <TitleAndDetailsView title="Previous Wallet Balance" details={data?.previousBalance} />
          <TitleAndDetailsView title="New Wallet Balance" details={data?.newBalance} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default TransactionInfoModal;
