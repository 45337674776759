import { Box } from "@mui/material";
import { useState } from "react";
import TableView from "./TableView";

import { MarginTop40Box, SearchContainer, PageHeaderTitle, DateRangeFilter, FilterDropdown, SearchInput, SearchButton, useDocumentTitle, useFilter, useAuthContext } from "suregifts-component-lib";
import { PaymentFilter } from "../../shared/transaction";
import { useExportPayments } from "../../services/payments";

const getMethods = (countryId) => {
  return [
    { text: "All" },
    { text: "Paypal", value: 2 },
    { text: "Bank Transfer", value: 3 },
    {
      text: "Credit/Debit Card",
      value: countryId == 2 ? 9 : 6,
    },
    {
      text: "Crypto",
      value: 7,
    },
  ];
};

export function PaymentsScreen() {
  const [filter, setFilter] = useFilter<PaymentFilter>({ page: 1, search: "" });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { mutate: exportData } = useExportPayments(filter as any);
  useDocumentTitle("Payments");
  const { user } = useAuthContext();
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="Payments" />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <DateRangeFilter display={"Date"} valueChanged={(params) => handleFilter(params)} />
          <FilterDropdown
            display={"Payment Method"}
            options={getMethods(user?.countryId)}
            value={filter.method}
            onChange={(index, value) => {
              handleFilter({ method: value, page: 1 });
            }}
          />
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />
          <SearchButton label="Export" onClick={() => exportData()} />
        </Box>
      </SearchContainer>
      <TableView filter={filter} handlerFilter={handleFilter} />
    </div>
  );
}
