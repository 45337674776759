import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { ApprovalStatusLabel, DialogTitle, FormButton, TitleAndDetailsView, TransactionStatusLabel, dateTime } from "suregifts-component-lib";

import { useQueryClient } from "react-query";
import { usePaymentModalState } from "./usePaymentModalState";
import { useApprovePayment } from "../../../services/payments";
import { PaymentData } from "../../../shared/transaction";

function ApprovePaymentModal() {
  const queryClient = useQueryClient();
  const { mutate: approve, isLoading } = useApprovePayment();
  const { handleClose, payment } = usePaymentModalState();
  const handleApprove = () => {
    approve(payment!.paymentNumber, {
      onSuccess: () => {
        toast.success("Payment approved successfully.");
        queryClient.invalidateQueries("payments");
        handleClose();
      },
    });
  };
  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Approve Payment"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <PaymnetDetailsView payment={payment!} />
          </Stack>
          <DialogActions>
            <FormButton color="success" fullWidth loading={isLoading} onClick={handleApprove} label="Approve Payment"></FormButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ApprovePaymentModal;
type PaymnetDetailsViewProps = {
  payment: PaymentData;
};

export const PaymnetDetailsView = ({ payment }: PaymnetDetailsViewProps) => {
  return (
    <>
      <TitleAndDetailsView title="Reference" details={payment.paymentNumber} />
      <TitleAndDetailsView title="Amount" details={payment.amount} />
      <TitleAndDetailsView title="Payment Method" details={payment.paymentMethod} />
      <TitleAndDetailsView title="Transaction Type" details={payment.paymentType} />
      <TitleAndDetailsView title="Company Name" details={payment.name} />
      <TitleAndDetailsView title="Business Unit" details={payment.unit} />
      <TitleAndDetailsView title="Sender's Email" details={payment.email} />
      <TitleAndDetailsView title="Payment Status" details={<TransactionStatusLabel status={payment.paymentStatusId} text={payment.paymentStatus} />} color="success.main" />
      <TitleAndDetailsView title="Approval Status" details={<ApprovalStatusLabel status={payment.approvalStatusId} text={payment.approvalStatus} />} color="success.main" />
      {payment.reviewedBy && (
        <>
          <TitleAndDetailsView title="Reviewed By" details={payment.reviewedBy} />
          <TitleAndDetailsView title="Reviewed Time" details={dateTime(payment.reviewedTime)} />
        </>
      )}
      {Object.keys(payment.extraInfo).map((key, index) => {
        return <TitleAndDetailsView key={key} title={key} details={payment.extraInfo[key]} />;
      })}
    </>
  );
};
