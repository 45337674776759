import { Box, SxProps, Typography } from "@mui/material";
import { OrderInfoDto } from "../../services/order";

interface UnitInfoViewProps {
  order?: OrderInfoDto;
}
export function UnitInfoView({ order }: UnitInfoViewProps) {
  return (
    <Box pl="1.885rem">
      <Typography sx={{ fontSize: "1.875rem", fontWeight: 700, color: "text.primary", overflow: "hidden", textOverflow: "ellipsis" }}>{order?.name}</Typography>
      <Typography sx={unitSx}>{order?.unit}</Typography>
      <Typography sx={unitSx}>RC number: {order?.rcNumber}</Typography>
    </Box>
  );
}
const unitSx: SxProps = { color: "text.secondary", fontSize: "14px" };
