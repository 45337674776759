import { Box } from "@mui/material";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MarginTop40Box, PageFilter, formatNumber, FilterDropdown } from "suregifts-component-lib";
import { ApiClientFilter } from "../../services/business";

type SearchSectionProps = {
  filter: ApiClientFilter;
  handleFilter: (params: any) => void;
  total?: number;
};

export const FilterSection = ({ filter, handleFilter, total }: SearchSectionProps) => {
  return (
    <MarginTop40Box>
      <SearchContainer>
        <PageHeaderTitle title={`${formatNumber(total)} Clients`} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown
            display={"Status"}
            options={[{ text: "All" }, { text: "Active", value: true }, { text: "Inative", value: false }]}
            value={filter.isActive}
            onChange={(index, value) => {
              handleFilter({ isActive: value, page: 1 });
            }}
          />
          <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
          <SearchButton label="Export" />
        </Box>
      </SearchContainer>
    </MarginTop40Box>
  );
};
