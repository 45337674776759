import { TableHead, TableRow, TableCell, IconButton, Typography } from "@mui/material";
import { MainTable, Pagination, MarginTop40Box, BooleanLabel, TableBody, useFilter } from "suregifts-component-lib";
import { useState } from "react";
import { FilterSection } from "./FilterSection";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { BusinessFilter, useGetBusinesses } from "../../services/business";
function BusinessScreen() {
  const [filter, setFilter] = useFilter<BusinessFilter>({ page: 1, search: "" });
  const { data, isFetching } = useGetBusinesses(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const navigate = useNavigate();
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Company </TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Activated</TableCell>
            <TableCell>Wallet Balance</TableCell>
            <TableCell>Amount Spent</TableCell>
            <TableCell>No of Orders</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.businessId}>
              <TableCell>
                <CompanyNameLabel name={item.name} unit={item.unit} />
              </TableCell>
              <TableCell>{item.ownerEmail}</TableCell>
              <TableCell>
                <BooleanLabel type="yesno" value={item.verified} />
              </TableCell>
              <TableCell>
                <BooleanLabel type="yesno" value={item.activated} />
              </TableCell>
              <TableCell>{item.walletBalance}</TableCell>
              <TableCell>{item.amountSpent}</TableCell>
              <TableCell>{item.totalOrders}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  onClick={() => {
                    navigate(`${item.businessId}`);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}
export const CompanyNameLabel = ({ name, unit }) => {
  return (
    <>
      <div>{name}</div>
      <Typography sx={{ fontSize: "14px", color: "text.secondary" }}>{unit}</Typography>
    </>
  );
};
export default BusinessScreen;
