import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { OrderStatusLabel, dateOnly, dateTime } from "suregifts-component-lib";
import { OrderInfoDto } from "../../services/order";
import { TitleInfoView } from "./TitleInfoView";
import { UnitInfoView } from "./UnitInfoView";

interface BusinessInfoView1Props {
  order?: OrderInfoDto;
}

export function BusinessInfoView({ order }: BusinessInfoView1Props) {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        mt: "2.5rem",
        p: "1.875rem 3.75rem 1.875rem  1.875rem",
        display: "flex",
        borderRadius: "1.875rem",
        alignItems: "center",
        gap: "2.5rem",
        flexWrap: "wrap",
      }}
    >
      <Box
        flexGrow={1}
        sx={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "40%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Box component={"img"} sx={{ width: "6.25rem", height: "6.25rem", borderRadius: 50, objectFit: "contain", backgroundColor: "secondary.main" }} src={order?.businessLogo}></Box>
        <UnitInfoView order={order} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "3.75rem", flexGrow: "1" }} className="order-info">
        <div style={{ display: "grid", gap: "10px" }}>
          <TitleInfoView title={"Order id"} info={order?.orderNumber} />
          <TitleInfoView title={"Order Status"} info={<OrderStatusLabel sx={{ fontSize: "1.25rem", fontWeight: 700 }} label={order?.status} status={order?.statusId} />} />
        </div>
        <div style={{ display: "grid", gap: "10px" }}>
          <TitleInfoView title={"Date Created"} info={dateTime(order?.dateCreated)} />
          <TitleInfoView title={"Delivery Date"} info={dateTime(order?.scheduledTime)} />
        </div>
        <TitleInfoView title={"Voucher Type"} info={order?.merchantName} />
      </Box>
    </Box>
  );
}
