import { useParams } from "react-router-dom";
import { useGetOrderById, useResendVoucher } from "../../services/order";
import { DeliveryStatusType, MainTable, OrderItemStatusLabel, PageFilter, PageHeaderTitle, Pagination, ResendIcon, SearchButton, SearchContainer, formatNumber, useDocumentTitle, useFilter, usePagination } from "suregifts-component-lib";
import { Box, IconButton, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { toast } from "react-toastify";
import { BusinessInfoView } from "./BusinessInfoView";
import { useMemo } from "react";
import OrderItemStatusIcon from "../../components/atoms/OrderItemStatusIcon";

function OrderInfoScreen() {
  const { orderId } = useParams();
  useDocumentTitle("Orders");
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1 });
  const { data, isLoading, refetch } = useGetOrderById(orderId);
  const { mutate: resendVoucher, isLoading: isReseding } = useResendVoucher(orderId);
  const handleResend = (recipientId) => {
    resendVoucher(recipientId, {
      onSuccess: () => {
        toast.success("Voucher resent successfully.");
        refetch();
      },
    });
  };
  const { data: recipients, total } = usePagination(filter.page, data?.recipients, filter.size);
  return (
    <div>
      <BusinessInfoView order={data} />
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={`${formatNumber(data?.recipients.length)} Recipients`} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchButton label="Export" />
        </Box>
      </SearchContainer>
      <MainTable sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Voucher Amount</TableCell>
            <TableCell>Resend</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipients.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <OrderItemStatusIcon statusId={item.statusId} />
              </TableCell>
              <TableCell>{item.firstName}</TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.amount}</TableCell>

              <TableCell>
                <IconButton disabled={item.statusId !== DeliveryStatusType.Delivered} onClick={() => handleResend(item.id)}>
                  <ResendIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination size={filter.size} total={total} page={filter.page} onChange={(page) => setFilter((prev) => ({ ...prev, page }))} />
      </div>
    </div>
  );
}

export default OrderInfoScreen;
