import { TableHead, TableRow, TableCell, Button, IconButton } from "@mui/material";
import { MainTable, Pagination, MarginTop40Box, PageFilter, TableBody, useFilter, IOSSwitch } from "suregifts-component-lib";
import { useState } from "react";
import { FilterSection } from "./FilterSection";
import { ApiClientFilter, useGetApiClients, useToggleClientStatus } from "../../services/business";
import { toast } from "react-toastify";
import { CompanyNameLabel } from "../business/BusinessScreen";
import { fixedCellStyle } from "../../helpers/styles";
function ApiClientsScreen() {
  const [filter, setFilter] = useFilter<ApiClientFilter>({ page: 1, search: "" });
  const { data, isFetching, refetch } = useGetApiClients(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { mutate: changeState, isLoading: isChangingState } = useToggleClientStatus();
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isFetching || isChangingState} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Public Key</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <CompanyNameLabel name={item.name} unit={item.unit} />
              </TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell style={fixedCellStyle}>{item.publicKey}</TableCell>
              <TableCell style={fixedCellStyle} sx={{ color: item.isActive ? "success.main" : "error.main" }}>
                {item.isActive ? "Activated" : "Deactivated"}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    var model = { businessId: item.businessId, credentialId: item.id };
                    changeState(model, {
                      onSuccess: () => {
                        refetch();
                      },
                    });
                  }}
                >
                  <IOSSwitch checked={item.isActive} color={item.isActive ? "error" : "success"} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default ApiClientsScreen;
