import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";

import { useQueryClient } from "react-query";
import { usePaymentModalState } from "./usePaymentModalState";
import { useRejectPayment } from "../../../services/payments";
import { PaymnetDetailsView } from "./ApprovePaymentModal";

function RejectPaymentModal() {
  const queryClient = useQueryClient();
  const { mutate: reject, isLoading: isRejecting } = useRejectPayment();
  const [rejectReason, setRejectReason] = useState("");

  const { handleClose, payment } = usePaymentModalState();

  const handleDeline = () => {
    reject(
      { paymentId: payment?.paymentNumber, rejectionReason: rejectReason },
      {
        onSuccess: () => {
          toast.success("Payment declined successfully.");
          queryClient.invalidateQueries("payments");
          handleClose();
        },
      }
    );
  };

  return (
    <div>
      <Dialog maxWidth={"xs"} open={true} sx={{}}>
        <DialogTitle title={"Reject Payment"} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={4}>
            <PaymnetDetailsView payment={payment!} />
            <FormInput label="Reason" required multiline size="medium" value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} />
          </Stack>
          <DialogActions>
            <FormButton color="primary" sx={{ color: "error.main" }} fullWidth loading={isRejecting} onClick={handleDeline} label="Decline Payment"></FormButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RejectPaymentModal;
