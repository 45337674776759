import { Box, IconButton, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MainTable, Pagination, useDocumentTitle, TableBody, useFilter, dateOnly } from "suregifts-component-lib";
import { TransactionFilter, useGetWalletTransactions } from "../../services/transaction";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Outlet, useNavigate } from "react-router-dom";
import { fixedCellStyle } from "../../helpers/styles";
import { CompanyNameLabel } from "../business/BusinessScreen";

function TransactionScreen() {
  const [filter, setFilter] = useFilter<TransactionFilter>({ page: 1, search: "" });
  const { data, isFetching } = useGetWalletTransactions(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Wallet Transactions");
  const navigate = useNavigate();
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title="All Transactions" />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
          <SearchButton label="Export" />
        </Box>
      </SearchContainer>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Company </TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Previous Balance</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow
              key={item.transactionId}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem("TRANSACTION_DATA", JSON.stringify(item));
                navigate("view");
              }}
            >
              <TableCell>
                <CompanyNameLabel name={item.name} unit={item.unit} />
              </TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell style={fixedCellStyle} sx={{ color: item.isCredit ? "success.main" : "error.main" }}>
                {item.amount}
              </TableCell>
              <TableCell style={fixedCellStyle}>{item.previousBalance}</TableCell>
              <TableCell width={"25%"}>{item.narration}</TableCell>
              <TableCell style={fixedCellStyle}>{dateOnly(item.dateCreated)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
      <Outlet />
    </div>
  );
}

export default TransactionScreen;
