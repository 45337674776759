import { MarginTop40Box, SummaryCardsContainer, SummaryCardWithIcon, MerchantPotIcon, CheckListIcon, DeliveryTruckIcon, PersonPlusIcon, useDocumentTitle } from "suregifts-component-lib";
import { SummaryData, useGetSummary } from "../../services/general";
import { useTheme } from "@mui/material";
import { RecentRegistrationSection } from "./RecentRegistrationSection";
import { RecentOrderSection } from "./RecentOrderSection";
import { RecentRegisterChartSection } from "./RecentRegisterChartSection";
import { SummaryCardsSection } from "./SummaryCardsSection";
function DashboardScreen() {
  const { data } = useGetSummary();
  const theme = useTheme();
  useDocumentTitle("Dashboard");
  return (
    <div>
      <SummaryCardsSection data={data} />
      <MarginTop40Box
        sx={{
          display: "grid",
          gap: "3.125rem",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        }}
      >
        <RecentRegistrationSection data={data?.recentRegistrations} />
        <RecentRegisterChartSection data={data?.registrationChart} />
      </MarginTop40Box>
      <RecentOrderSection />
    </div>
  );
}

export default DashboardScreen;
