import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateCategoryModel, DialogTitle, FormButton, FormInput, YupShape, useCreateCategory } from "suregifts-component-lib";

const schema = yup
  .object<YupShape<CreateCategoryModel>>({
    name: yup.string().required(),
  })
  .required();
function CreateCategoryModal({ open, handleClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateCategoryModel>({
    resolver: yupResolver(schema),
  });
  const { mutate: create, isLoading } = useCreateCategory();
  const submitForm = (model) => {
    create(model, {
      onSuccess: () => {
        toast.success("Category created successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle title="Create New Category" handleClose={handleClose} />
      <DialogContent>
        <FormInput label="Category Name" {...register("name")} helperText={errors.name?.message} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Save Category" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default CreateCategoryModal;
