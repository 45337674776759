import { useMutation, useQuery } from "react-query";
import { Count, PageFilter, ServerError, useAxios } from "suregifts-component-lib";

export const useGetBusinesses = (filter: BusinessFilter) => {
  const path = "/businesses/api/businesses";
  const axios = useAxios();
  return useQuery<Count<BusinessData>, ServerError>({
    queryKey: ["businesses", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};
export const useGetBusinessById = (businessId?: string) => {
  const axios = useAxios();
  return useQuery<BusinessInfoDto, ServerError>({
    queryKey: ["business-byId", { businessId }],
    keepPreviousData: true,
    queryFn: () => axios.get(`/businesses/api/businesses/${businessId}`, {}),
  });
};
export const useToggleBusinessStatus = (businessId) => {
  const axios = useAxios();

  return useMutation<unknown, ServerError>(() => {
    return axios.post(`/businesses/api/businesses/${businessId}/toggle-active`);
  });
};

export const useChangeOwner = (businessId) => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, ChangeOwnerModel>((model) => {
    return axios.post(`/businesses/api/businesses/${businessId}/change-owner`, model);
  });
};
export const useGetApiClients = (filter: ApiClientFilter) => {
  const path = "/businesses/api/credentials";
  const axios = useAxios();
  return useQuery<Count<ApiClientData>, ServerError>({
    queryKey: ["api-clients", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};
export interface ApiClientFilter extends PageFilter {
  isActive: boolean;
}
export const useToggleClientStatus = () => {
  const axios = useAxios();

  return useMutation<unknown, ServerError, ToggleClientStatusModel>((model) => {
    return axios.post(`/businesses/api/credentials/${model.businessId}/toggle-active/${model.credentialId}`);
  });
};
export interface BusinessFilter extends PageFilter {}
interface ToggleClientStatusModel {
  credentialId;
  businessId;
}
export interface ChangeOwnerModel {
  firstName: string;
  lastName: string;
  email: string;
}

export interface BusinessData {
  name: string;
  unit: string;
  verified: boolean;
  activated: boolean;
  walletBalance: string;
  amountSpent: string;
  totalOrders: number;
  businessId: string;
  ownerName: string;
  ownerEmail: string;
}
export interface BusinessInfoDto extends BusinessData {
  logo: string;
  ownerName: string;
  ownerEmail: string;
  walletBalance: string;
  creationDate: string;
  rcNumber: string;
}
export interface ApiClientData {
  id: number;
  name: string;
  unit: string;
  email: string;
  publicKey: string;
  isActive: boolean;
  businessId: string;
  isEnabled: boolean;
}
