import * as yup from "yup";
import { ApprovalStatusType, PageFilter, TransactionStatusType, YupShape } from "suregifts-component-lib";
export const debitCreditSchema = yup
  .object<YupShape<CreditDebitModel>>({
    amount: yup.string().required(),
    reason: yup.string().required(),
  })
  .required();
export interface CreditDebitModel {
  amount: number;
  reason: string;
}
export interface PaymentData {
  paymentNumber: string;
  amount: string;
  paymetMethodId: number;
  paymentMethod: string;
  paymentTypeId: number;
  paymentType: string;
  approvalStatusId: ApprovalStatusType;
  approvalStatus: string;
  paymentStatusId: TransactionStatusType;
  paymentStatus: string;
  unit: string;
  name: string;
  email: string;

  dateCreated: string;
  reviewedTime: string;
  reviewedBy: string;
  rejectionReason: string;
  extraInfo: any;
}

export interface PaymentFilter extends PageFilter {
  fromDate?: Date;
  toDate?: Date;
  method: any;
}
