import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import { DialogTitle, FormButton, FormInput, TitleAndDetailsView, formatMoney } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { CreditDebitModel, debitCreditSchema } from "../../../shared/transaction";
import { useCreditWallet } from "../../../services/transaction";
import { useBusinessModalState } from "./useBusinessModalState";

function CreditBusinessModal() {
  const { handleClose, info, isConfirm, reload, setIsConfirm, user } = useBusinessModalState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreditDebitModel>({
    defaultValues: {},
    resolver: yupResolver(debitCreditSchema),
  });
  const { mutate: credit, isLoading } = useCreditWallet(info?.businessId);
  const submitForm = (data) => {
    if (!isConfirm) {
      setIsConfirm(true);
    } else {
      credit(data, {
        onSuccess: () => {
          toast.success("Wallet Credited successfully.");
          handleClose();
        },
      });
    }
  };
  return (
    <Dialog open maxWidth={"xs"}>
      <DialogTitle title={isConfirm ? "Confirm Topup" : "Enter Amount"} handleClose={handleClose} />
      <DialogContent>
        {isConfirm ? (
          <Stack spacing={3}>
            <TitleAndDetailsView title="Company Name" details={info?.name} />
            <TitleAndDetailsView title="Business Unit" details={info?.unit} />
            <TitleAndDetailsView title="RC Number" details={info?.rcNumber} />
            <TitleAndDetailsView title="Creation Date" details={info?.creationDate} />
            <TitleAndDetailsView title="Amount" details={formatMoney(watch("amount"), user?.currency)} color="success.main" />
            <TitleAndDetailsView title="Topup Reason" details={watch("reason")} />
          </Stack>
        ) : (
          <Stack spacing={3}>
            <FormInput label="Credit Amout" helperText={errors.amount?.message} {...register("amount")} />
            <FormInput label="Reason" size="medium" multiline helperText={errors.reason?.message} {...register("reason")} />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label={isConfirm ? "Confirm Topup" : "Top-up Wallet"} loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default CreditBusinessModal;
