import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "suregifts-component-lib";
import { useBusinessInfoScreenContext } from "../BusinessInfoScreenContext";

export const useBusinessModalState = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const { user } = useAuthContext();
  const { business: info } = useBusinessInfoScreenContext();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const queryClient = useQueryClient();
  return {
    isConfirm,
    setIsConfirm,
    info,
    user,
    handleClose,
    reload: () => {
      queryClient.invalidateQueries("transactions");
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("business-byId");
    },
  };
};
