import React from "react";
import { ApprovalStatusType, DeliveryStatusType, ErrorTickIcon, OrderStatusType, SuccessTickIcon } from "suregifts-component-lib";
import ErrorIcon from "@mui/icons-material/Error";
type PaymentStatusIconProps = {
  statusId: DeliveryStatusType;
};
function OrderItemStatusIcon({ statusId }: PaymentStatusIconProps) {
  if (statusId === DeliveryStatusType.Delivered) return <SuccessTickIcon color="success" />;
  if (statusId === DeliveryStatusType.Failed) return <ErrorTickIcon color="error" />;
  return <SuccessTickIcon color="warning" />;
}

export default OrderItemStatusIcon;
