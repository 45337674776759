import { useQuery } from "react-query";
import { Count, OrderStatusType, PageFilter, ServerError, TransactionType, WalletType, useAxios } from "suregifts-component-lib";
export const useGetUtilities = (filter: UtilityFilter) => {
  const path = "/utilities/api";
  const axios = useAxios();
  return useQuery<Count<UtilityData>, ServerError>({
    queryKey: ["orders", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};
export interface UtilityFilter extends PageFilter {
  type?: any;
}
export interface UtilityData {
  id: number;
  billTypeId: TransactionType;
  billType: string;
  statusId: OrderStatusType;
  status: string;
  subTotal: number;
  narration: string;
  dateCreated: string;
  quantity: number;
  paymnetMethodId: WalletType;
  paymentMethod: string;
  name: string;
  email: string;
  unit: string;
}
