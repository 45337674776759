import { PageHeaderTitle } from "suregifts-component-lib";
import { ResponsivePie } from "@nivo/pie";
import { RegistrationChart } from "../../services/general";
import { Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
type LegendLabelProps = {
  label: string;
  color: string;
};
const Dot = ({ color }) => {
  return <span style={{ backgroundColor: color, display: "inline-block", width: "0.625rem", height: "0.625rem", borderRadius: "50vh", marginRight: "0.625rem" }}></span>;
};
const LegendLabel = ({ label, color }: LegendLabelProps) => {
  return (
    <Typography sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
      <Dot color={color} />
      {label}
    </Typography>
  );
};
const labels = ["Verified & Activated", "Verified but not Activated", "Not Verified & Not Activated"];
const colors = ["rgba(118, 123, 255, 1)", "rgba(252, 159, 147, 1)", "rgba(164, 219, 255, 1)"];

type RecentRegisterChartSectionProps = {
  data?: RegistrationChart;
};
export const RecentRegisterChartSection = ({ data }: RecentRegisterChartSectionProps) => {
  const theme = useTheme();
  const d = useMemo(() => {
    return [
      {
        id: "Verified & Activated",
        label: "Verified & Activated",
        value: data?.activeVerified,
        color: "rgba(118, 123, 255, 1)",
      },
      {
        id: "Verified but not Activated",
        label: "Verified but not Activated",
        value: data?.verified,
        color: "rgba(252, 159, 147, 1)",
      },
      {
        id: "Not Verified & Not Activated",
        label: "Not Verified & Not Activated",
        value: data?.notVerified,
        color: "#ff0000",
      },
    ];
  }, [data]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.625rem", overflow: "hidden" }}>
      <PageHeaderTitle title="Recent Registered" />
      <div style={{ flexShrink: 3, height: "25rem" }}>
        {data && (
          <ResponsivePie
            data={[
              {
                id: `${data.activeVerified}%`,
                label: labels[0],
                value: data.activeVerified,
              },
              {
                id: `${data.verified}%`,
                label: labels[1],
                value: data.verified,
              },
              {
                id: `${data.notVerified}%`,
                label: labels[2],
                value: data.notVerified,
              },
            ]}
            margin={{ top: 0, right: 80, bottom: 0, left: 80 }}
            innerRadius={0.8}
            padAngle={0.7}
            cornerRadius={10}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={theme.palette.text.primary}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            colors={colors}
          />
        )}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
        <LegendLabel color={colors[0]} label={labels[0]} />
        <LegendLabel color={colors[1]} label={labels[1]} />
        <LegendLabel color={colors[2]} label={labels[2]} />
      </div>
    </div>
  );
};
