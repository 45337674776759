import { Box, IconButton, TableCell, TableHead, TableRow } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { MarginTop40Box, SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MainTable, Pagination, TableBody, OrderStatusLabel, useFilter, dateOnly } from "suregifts-component-lib";
import { BusinessOrderType, OrderFilter, useGetOrders } from "../../services/order";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fixedCellStyle } from "../../helpers/styles";
function BusinessOrderScreen() {
  const { businessId } = useParams();
  const [filter, setFilter] = useFilter<OrderFilter>({ page: 1, search: "" });
  const { data, isFetching, refetch } = useGetOrders({ ...filter, businessId });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  const navigate = useNavigate();

  return (
    <div>
      <MarginTop40Box>
        <SearchContainer>
          <PageHeaderTitle title={`${data?.total} Orders`} />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
            <SearchButton label="Export" />
          </Box>
        </SearchContainer>
      </MarginTop40Box>
      <MarginTop40Box>
        <MainTable loading={isFetching}>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Narration</TableCell>
              <TableCell>No of Recipient</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.orderNumber}</TableCell>
                <TableCell style={fixedCellStyle}>{dateOnly(item.dateCreated)}</TableCell>
                <TableCell style={fixedCellStyle}>{item.subTotal}</TableCell>
                <TableCell>{item.narration}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell style={fixedCellStyle}>
                  <OrderStatusLabel label={item.status} status={item.statusId} />
                </TableCell>
                <TableCell style={fixedCellStyle}>
                  <IconButton
                    disabled={item.orderTypeId === BusinessOrderType.Download}
                    onClick={() => {
                      navigate(`/orders/${item.id}`);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
        <div style={{ marginTop: "1.25rem" }}>
          <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
        </div>
      </MarginTop40Box>
    </div>
  );
}

export default BusinessOrderScreen;
