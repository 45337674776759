import { useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export const useGetSummary = () => {
  const path = "/general/api/summary";
  const axios = useAxios();
  return useQuery<SummaryData, ServerError>({
    queryKey: ["summary-data"],
    keepPreviousData: true,
    queryFn: () => axios.get(path, {}),
  });
};

export interface SummaryData {
  totalOrders: number;
  totalBusiness: number;
  totalCompanies: number;
  totalBalance: number;
  recentRegistrations: RecentRegistration[];
  registrationChart: RegistrationChart;
}

export interface RecentRegistration {
  email: string;
  businessId: string;
  name: string;
  unit: string;
  registeredDate: string;
  logo: string;
}

export interface RegistrationChart {
  activeVerified: number;
  verified: number;
  notVerified: number;
}
