import { Box } from "@mui/material";
import { MarginTop40Box, PageHeaderTitle, TemplateContextProdiver, TemplateEditForm, TemplateEditorView, TemplateLayoutView, TemplateThumbnailView, useDocumentTitle, useTemplateContext } from "suregifts-component-lib";
import "quill/dist/quill.snow.css";

import { CreateCategorySection } from "./CreateCategorySection";
import { TemplateActionButtons } from "./TemplateActionButtons";
import { useEffect } from "react";

function EmailTemplateScreen() {
  useDocumentTitle("Email Templates");
  useEffect(() => {}, []);

  return (
    <div style={{}}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginY: "40px" }}>
        <PageHeaderTitle title="Create Email Template" />
        <CreateCategorySection />
      </Box>
      <TemplateContextProdiver isAdmin={true}>
        <TemplateLayoutView />
      </TemplateContextProdiver>
    </div>
  );
}

export default EmailTemplateScreen;
