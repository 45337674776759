import React from "react";
import { BusinessInfoView } from "./BusinessInfoView";
import { MarginTop40Box, useDocumentTitle } from "suregifts-component-lib";
import BusinessTransactionScreen from "./BusinessTransactionScreen";
import { Outlet, useSearchParams } from "react-router-dom";
import BusinessOrderScreen from "./BusinessOrderScreen";
import { useBusinessInfoScreenContext } from "./BusinessInfoScreenContext";

function BusinessInfoScreen() {
  const { business: info } = useBusinessInfoScreenContext();
  useDocumentTitle(info?.unit || "Business Units");
  const [searchParams] = useSearchParams();
  return (
    <div>
      <BusinessInfoView />
      {searchParams.get("tab") === "orders" ? <BusinessOrderScreen /> : <BusinessTransactionScreen />}
      <Outlet />
    </div>
  );
}

export default BusinessInfoScreen;
