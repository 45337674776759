import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormInput, FormButton, useAuthContext, LoginModel } from "suregifts-component-lib";
import { useLogin } from "../../../services/auth";

const LogoImage = () => {
  return <img src="/assets/images/login-top-bg.png" style={{ width: "100%" }} />;
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const auth = useAuthContext();
  const { mutate: login, isLoading, error } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginModel>({});

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/");
    }
  }, [auth.user]);

  const submitForm = (data: LoginModel) => {
    login(data, {
      onSuccess: (data) => {
        auth.saveToken(data.token, data.refreshToken, data);
      },
    });
  };
  return (
    <Box sx={{ width: "26.875rem", maxWidth: "26.875rem", background: "#000000", backdropFilter: " blur(1.25rem)", borderRadius: "2.1875rem" }}>
      <LogoImage />
      <Box sx={{ p: "2.5rem", position: "relative" }} component={"form"} onSubmit={handleSubmit(submitForm)}>
        <Box>
          <Stack spacing={2}>
            <FormInput label="Username" placeholder="Enter User Name" {...register("email", { required: { value: true, message: "Email is required" } })} helperText={errors.email?.message} />
            <FormInput label="Password" type="password" placeholder="Enter Password" {...register("password", { required: { value: true, message: "Password is required." } })} helperText={errors.password?.message} />
          </Stack>
          <FormButton sx={{ mt: 3 }} loading={isLoading} fullWidth type="submit" label="Sign in" color="secondary" />
        </Box>
      </Box>
    </Box>
  );
};
