import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { MainTable, OrderStatusLabel, Pagination, TableBody, dateOnly, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { useState } from "react";
import { FilterSection } from "./FilterSection";
import OrderStatusIcon from "../../components/atoms/OrderStatusIcon";
import { UtilityFilter, useGetUtilities } from "../../services/utility";
import { fixedCellStyle } from "../../helpers/styles";
import { CompanyNameLabel } from "../business/BusinessScreen";

function UtlitiesScreen() {
  const [filter, setFilter] = useFilter<UtilityFilter>({ page: 1, search: "" });
  const { data, isLoading } = useGetUtilities(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Bills Payments");
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>No of Recipient</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <CompanyNameLabel name={item.name} unit={item.unit} />
              </TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>

              <TableCell style={fixedCellStyle}>{item.narration}</TableCell>
              <TableCell style={fixedCellStyle}>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.quantity}</TableCell>

              <TableCell>{item.subTotal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default UtlitiesScreen;
