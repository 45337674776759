import { TableRow, TableCell, Box } from "@mui/material";
import moment from "moment";
import { PageTitleWithLink, MainTable, TableBody, dateOnly } from "suregifts-component-lib";
import { RecentRegistration } from "../../services/general";
import { CompanyNameLabel } from "../business/BusinessScreen";
import { fixedCellStyle } from "../../helpers/styles";

type RecentRegistrationSectionProps = {
  data?: RecentRegistration[];
};
export const RecentRegistrationSection = ({ data }: RecentRegistrationSectionProps) => {
  return (
    <div>
      <PageTitleWithLink to="/businesses" title="Recent Registration" />
      <MainTable>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.businessId}>
              <TableCell width={"5%"}>
                <Box component={"img"} src={item.logo || "/assets/images/profile-dummy.png"} sx={{ width: "3.125rem", height: "3.125rem", borderRadius: "50vh", objectFit: "cover" }}></Box>
              </TableCell>
              <TableCell>
                <CompanyNameLabel name={item.name} unit={item.unit} />
              </TableCell>
              <TableCell style={fixedCellStyle}>{item.email}</TableCell>
              <TableCell style={fixedCellStyle}>{dateOnly(item.registeredDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
};
