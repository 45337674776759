import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { NameAndId, ProfileData, ServerError, getCaptchaToken, useAxios } from "suregifts-component-lib";
export const useLogOut = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, any>((_) => {
    return axios.post("/admin-security/api/logout");
  });
};
export const useLogin = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, LoginModel>(async (values) => {
    return axios.post("/admin-security/api/login", values, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export const useChangePassword = () => {
  var axios = useAxios();

  return useMutation<LoginResponse, ServerError, ChangePasswordModel>((values) => {
    return axios.post("/admin-security/api/change-password", values);
  });
};
export const useUpdateProfile = () => {
  var axios = useAxios();

  return useMutation<ProfileData, ServerError, UpdateProfileModel>((values) => {
    return axios.post("/admin-security/api/profile", values);
  });
};
export const useGetPhoneCountries = () => {
  const path = "/admin-security/api/GetPhoneCountries";
  const axios = useAxios();
  return useQuery<CountryDto[], ServerError, NameAndId[]>({
    queryKey: ["roles"],
    queryFn: () => axios.get(path),
    initialData: [],
    select: (data) => data.map((x) => ({ id: x.isoCode, name: `${x.name}(${x.dailCode})` })),
  });
};
export interface CountryDto {
  name: string;
  dailCode: string;
  isoCode: string;
}

export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
export interface LoginModel {
  email: string;
  password: string;
}
export interface RefreshTokenModel {
  refreshToken: string;
}
export interface ResetPasswordModel {
  email: string;
}
export interface UpdateProfileModel {
  firstName: string;
  lastName: string;
}

export interface LoginResponse extends ProfileData {
  refreshToken: string;
  token: string;
}
