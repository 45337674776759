import { Button } from "@mui/material";
import CreateCategoryModal from "./CreateCategoryModal";
import { useState } from "react";

export const CreateCategorySection = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button sx={{ padding: "8px 27px", borderRadius: "10px" }} color="secondary" onClick={() => setOpen(true)}>
          Create Category
        </Button>
      </div>
      <CreateCategoryModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};
