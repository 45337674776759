import { Outlet, createBrowserRouter } from "react-router-dom";
import LoginScreen from "../pages/auth/login/LoginScreen";
import { RequireAuth } from "./RequireAuth";
import NavBar from "../pages/root/NavBar";

import DashboardScreen from "../pages/dashboard/DashboardScreen";
import { TemplateContextProdiver, AdminRootScreen, CouponsScreen, AdminScreen, couponRoutes, adminRoutes, ScrollToTop } from "suregifts-component-lib";
import { PaymentsScreen } from "../pages/Payments/PaymentsScreen";
import OrdersScreen from "../pages/orders/OrdersScreen";
import OrderInfoScreen from "../pages/order-info/OrderInfoScreen";
import BusinessesScreen from "../pages/business/BusinessScreen";
import { BusinessInfoContextProvider } from "../pages/business-info/BusinessInfoScreenContext";
import BusinessTransactionScreen from "../pages/business-info/BusinessTransactionScreen";
import BusinessOrderScreen from "../pages/business-info/BusinessOrderScreen";
import TransactionScreen from "../pages/transactions/TransactionScreen";
import UtlitiesScreen from "../pages/utilities/UtlitiesScreen";
import EmailTemplateScreen from "../pages/email-templates/EmailTemplateScreen";
import ApiClientsScreen from "../pages/api-clients/ApiClientsScreen";
import { CSSProperties } from "react";
import CreditBusinessModal from "../pages/business-info/modals/CreditBusinessModal";
import DebitBusinessModal from "../pages/business-info/modals/DebitBusinessModal";
import TransactionInfoModal from "../pages/business-info/modals/TransactionInfoModal";
import ApprovePaymentModal from "../pages/Payments/modals/ApprovePaymentModal";
import RejectPaymentModal from "../pages/Payments/modals/RejectPaymentModal";
import ViewPaymentModal from "../pages/Payments/modals/ViewPaymentModal";

export const router = createBrowserRouter([
  {
    element: (
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
    ),
    children: [
      {
        path: "/",
        element: (
          <RequireAuth>
            <AdminRootScreen nav={<NavBar />} />
          </RequireAuth>
        ),
        errorElement: <div>Error ocred</div>,
        children: [
          { element: <DashboardScreen />, index: true },
          couponRoutes(),
          {
            path: "email-templates",
            element: (
              <TemplateContextProdiver>
                <EmailTemplateScreen />
              </TemplateContextProdiver>
            ),
          },
          adminRoutes(),
          {
            path: "payments",
            element: <PaymentsScreen />,
            children: [
              { path: ":paymentId/approve", element: <ApprovePaymentModal /> },
              {
                path: ":paymentId/reject",
                element: <RejectPaymentModal />,
              },
              {
                path: ":paymentId/view",
                element: <ViewPaymentModal />,
              },
            ],
          },
          {
            path: "transactions",
            element: <TransactionScreen />,
            children: [
              {
                path: "view",
                element: <TransactionInfoModal />,
              },
            ],
          },
          { path: "api-credentials", element: <ApiClientsScreen /> },
          { path: "businesses", element: <BusinessesScreen /> },
          {
            path: "businesses/:businessId",
            element: <BusinessInfoContextProvider />,
            children: [
              {
                path: "credit",
                element: <CreditBusinessModal />,
              },
              {
                path: "debit",
                element: <DebitBusinessModal />,
              },
              {
                path: "view",
                element: <TransactionInfoModal />,
              },
            ],
          },
          { path: "orders", element: <OrdersScreen /> },
          { path: "utilities", element: <UtlitiesScreen /> },
          {
            path: "orders/:orderId",
            element: <OrderInfoScreen />,
          },
        ],
      },
      {
        path: "/login",
        element: <LoginScreen />,
      },
    ],
  },
]);
