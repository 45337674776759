import { useQuery, useMutation } from "react-query";
import { Count, PageFilter, ServerError, useAxios } from "suregifts-component-lib";
import { CreditDebitModel } from "../shared/transaction";

export const useCreditWallet = (businessId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((model) => {
    return axios.post(`/transactions/api/${businessId}/credit-wallet`, model);
  });
};
export const useDebitWallet = (businessId) => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, CreditDebitModel>((model) => {
    return axios.post(`/transactions/api/${businessId}/debit-wallet`, model);
  });
};
export const useGetWalletTransactions = (filter: TransactionFilter) => {
  const axios = useAxios();
  return useQuery<Count<WalletTransactionData>, ServerError>({
    queryKey: ["transactions", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(`/transactions/api`, {
        params: filter,
      }),
  });
};
export const useExportWalletTransactions = (filter: TransactionFilter) => {
  var axios = useAxios();
  return useMutation(() => {
    return axios.get(`/transactions/api/export`, {
      params: filter,
    });
  });
};

export interface TransactionFilter extends PageFilter {
  businessId: any;
  rcNumber;
}
export interface WalletTransactionData {
  clientId: string;
  firstName: string;
  lastName: string;
  unit: string;
  name: string;
  email: string;
  transactionId: string;
  invoiceNumber: string;
  amount: string;
  previousBalance: string;
  newBalance: string;
  isCredit: boolean;
  dateCreated: string;
  extraInfo: object;
  externalId: string;
  transactionTypeId: number;
  transactionType: string;
  narration: string;
}
