import { createContext, useContext } from "react";
import { BusinessInfoDto, useGetBusinessById } from "../../services/business";
import BusinessInfoScreen from "./BusinessInfoScreen";
import { Outlet, useParams } from "react-router-dom";

export type ScreenContextProps = {
  business?: BusinessInfoDto;
  reload: () => void;
};

const BusinessInfoScreenContext = createContext({} as ScreenContextProps);
export const useBusinessInfoScreenContext = () => {
  return useContext(BusinessInfoScreenContext);
};

export const BusinessInfoContextProvider = () => {
  const { businessId } = useParams();
  const { data: business, isFetched, refetch } = useGetBusinessById(businessId);

  return (
    <BusinessInfoScreenContext.Provider
      value={{
        reload: refetch,
        business,
      }}
    >
      <BusinessInfoScreen />
    </BusinessInfoScreenContext.Provider>
  );
};
