import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";
import { MainTable, Pagination, MarginTop40Box, useDocumentTitle, TableBody, OrderStatusLabel, useFilter, dateOnly } from "suregifts-component-lib";
import { BusinessOrderType, OrderFilter, useGetOrders } from "../../services/order";
import { CSSProperties, useState } from "react";
import { FilterSection } from "./FilterSection";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { fixedCellStyle } from "../../helpers/styles";
function OrdersScreen() {
  const [filter, setFilter] = useFilter<OrderFilter>({ page: 1, search: "" });
  const { data, isLoading } = useGetOrders(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const navigate = useNavigate();
  useDocumentTitle("Orders");
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Company </TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>No of Recipient</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.orderNumber}</TableCell>
              <TableCell>
                {item.name}
                <br />
                <span style={{ fontSize: "13px" }}>{item.unit}</span>
              </TableCell>
              <TableCell>{item.email}</TableCell>

              <TableCell style={fixedCellStyle}>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell style={fixedCellStyle}>{item.subTotal}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell style={fixedCellStyle}>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  disabled={item.orderTypeId === BusinessOrderType.Download}
                  onClick={() => {
                    navigate(`${item.id}`);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default OrdersScreen;
