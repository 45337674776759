import { TableRow, TableCell, IconButton, TableHead } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MarginTop40Box, PageTitleWithLink, MainTable, TableBody, dateOnly } from "suregifts-component-lib";
import { useGetRecentOrders, BusinessOrderType } from "../../services/order";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fixedCellStyle } from "../../helpers/styles";
import { CompanyNameLabel } from "../business/BusinessScreen";
export const RecentOrderSection = () => {
  const { data, isLoading } = useGetRecentOrders();
  const navigate = useNavigate();
  return (
    <MarginTop40Box>
      <PageTitleWithLink to="/orders" title="Recent Orders" />
      <MainTable loading={isLoading}>
        <TableHead>
          <TableCell></TableCell>
          <TableCell sx={{ width: "25%" }}></TableCell>
          <TableCell sx={{ width: "25%" }}></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.orderNumber}</TableCell>
              <TableCell sx={{}}>{item.name}</TableCell>
              <TableCell style={fixedCellStyle}>{item.email}</TableCell>
              <TableCell style={fixedCellStyle}>{item.merchantName}</TableCell>
              <TableCell style={fixedCellStyle}>{item.subTotal}</TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>

              <TableCell>
                <IconButton
                  disabled={item.orderTypeId === BusinessOrderType.Download}
                  onClick={() => {
                    navigate(`/orders/${item.id}`);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </MarginTop40Box>
  );
};
