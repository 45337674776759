import { Box } from "@mui/material";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MarginTop40Box, formatNumber } from "suregifts-component-lib";
import { OrderFilter } from "../../services/order";

type SearchSectionProps = {
  filter: OrderFilter;
  handleFilter: (params: any) => void;
  total?: number;
};

export const FilterSection = ({ filter, handleFilter, total }: SearchSectionProps) => {
  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title={`${formatNumber(total)} Orders`} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
        <SearchButton label="Export" />
      </Box>
    </SearchContainer>
  );
};
