import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentData } from "../../../shared/transaction";

export const usePaymentModalState = () => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const [payment] = useState(() => {
    const data = sessionStorage.getItem("PAYMENT_DATA");
    if (!data) return {} as PaymentData;
    return JSON.parse(data) as PaymentData;
  });
  return { handleClose, payment };
};
