import { Box, SxProps } from "@mui/system";
import { LoginForm } from "./LoginForm";
const styles: SxProps = {
  background: "conic-gradient(from 151.11deg at 50% 50%, #CC9AC7 0deg, #A082F4 13.37deg, #A6E8F7 106.88deg, #FFF5DD 204.37deg, #FFF4D9 305.62deg, #CC9AC7 360deg)",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
function LoginScreen() {
  return (
    <Box sx={styles}>
      <LoginForm />
    </Box>
  );
}

export default LoginScreen;
