import { Box, IconButton, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MainTable, Pagination, TableBody, useFilter } from "suregifts-component-lib";
import { TransactionFilter, useGetWalletTransactions } from "../../services/transaction";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import { fixedCellStyle } from "../../helpers/styles";

function BusinessTransactionScreen() {
  const { businessId } = useParams();
  const [filter, setFilter] = useFilter<TransactionFilter>({ page: 1, search: "" });
  const { data, isFetching, refetch } = useGetWalletTransactions({ ...filter, businessId });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: "40px" }}>
      <SearchContainer>
        <PageHeaderTitle title="All Transactions" />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
          <SearchButton label="Export" />
        </Box>
      </SearchContainer>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Previous Balance</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.transactionId}>
              <TableCell>{item.transactionId}</TableCell>
              <TableCell style={fixedCellStyle}>
                <Typography color={item.isCredit ? "success.main" : "error.main"}>{item.amount}</Typography>
              </TableCell>
              <TableCell style={fixedCellStyle}>{item.previousBalance}</TableCell>
              <TableCell>{item.narration}</TableCell>
              <TableCell style={fixedCellStyle}>{item.transactionType}</TableCell>
              <TableCell style={fixedCellStyle}>{moment(item.dateCreated).format("DD.MM.YYYY")}</TableCell>
              <TableCell style={fixedCellStyle}>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("TRANSACTION_DATA", JSON.stringify(item));
                    navigate("view");
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default BusinessTransactionScreen;
