import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { Stack } from "@mui/system";
import { DialogTitle, FormButton, FormInput, TitleAndDetailsView, formatMoney, useAuthContext } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CreditDebitModel, debitCreditSchema } from "../../../shared/transaction";
import { useBusinessInfoScreenContext } from "../BusinessInfoScreenContext";
import { useDebitWallet } from "../../../services/transaction";
import { useBusinessModalState } from "./useBusinessModalState";

function DebitBusinessModal() {
  const { handleClose, info, isConfirm, reload, setIsConfirm, user } = useBusinessModalState();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreditDebitModel>({
    defaultValues: {},
    resolver: yupResolver(debitCreditSchema),
  });
  const { mutate: debit, isLoading } = useDebitWallet(info?.businessId);
  const submitForm = (data) => {
    if (!isConfirm) {
      setIsConfirm(true);
    } else {
      debit(data, {
        onSuccess: () => {
          reload();
          toast.success("Wallet Debitted successfully.");
          handleClose();
        },
      });
    }
  };

  return (
    <Dialog open maxWidth={"xs"}>
      <DialogTitle title={isConfirm ? "Confirm Debit" : "Enter Amount"} handleClose={handleClose} />
      <DialogContent>
        {isConfirm ? (
          <Stack spacing={3}>
            <TitleAndDetailsView title="Company Name" details={info?.name} />
            <TitleAndDetailsView title="Business Unit" details={info?.unit} />
            <TitleAndDetailsView title="RC Number" details={info?.rcNumber} />

            <TitleAndDetailsView title="Creation Date" details={info?.creationDate} />
            <TitleAndDetailsView title="Amount" details={formatMoney(watch("amount"), user?.currency)} color="error.main" />
            <TitleAndDetailsView title="Topup Reason" details={watch("reason")} />
          </Stack>
        ) : (
          <Stack spacing={3}>
            <FormInput label="Debit Amout" helperText={errors.amount?.message} {...register("amount")} />
            <FormInput label="Reason" size="medium" multiline helperText={errors.reason?.message} {...register("reason")} />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label={isConfirm ? "Confirm Debit" : "Debit Wallet"} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default DebitBusinessModal;
